import ExcelIcon from '@account-root/account-react/src/assets/icons/excel-white.svg'
import DateFnsUtils from '@date-io/date-fns'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { InputUpload } from '@ifca-root/react-component/src/components/Input/InputUpload'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { BankReconExcelForm } from './BankReconsilationExcelFn'

interface ISnackBarProps {
  openSnackBar: boolean
  snackBarMessage: string
  setOpenSnackBar?: React.Dispatch<boolean>
  onClickButton?: any
  label?: string
}
interface IBankReconExcelInputProps {
  openUpload: boolean
  setOpenUpload: any
  upload: string
  template: any
  onDownloadTemplate: any
  onSelectFile: any
  setUpload: any
  setTemplate: any
  onUploadTemplate: any
  snackBarProps: ISnackBarProps
  showDupErrors?: boolean
  duplicateItems?: any
  errorDia?: boolean
  setErrorDia?: React.Dispatch<boolean>
  resetFileUpload?: any
  showFooter?: boolean
  date?: Date
  setDate?: any
  description?: string
  setDescription?: any
  IsExcelMatch?: boolean
  setIsExcelMatch?: any
  StatementBalance?: number
  setStatementBalance?: any
  onCreateWihoutExcel?: any
  onUpdateWihoutExcel?: any
  editValue?: any
  reUpload?: boolean
}

export interface BankReconciliationProps {
  BankReconciliationID: String
  CompanyID: String
  BankAccountID: String
  DocumentDate: String
  Remarks: String
}

export const BankReconExcelInput = (props: IBankReconExcelInputProps) => {
  const {
    openUpload,
    setOpenUpload,
    upload,
    onDownloadTemplate,
    onSelectFile,
    setUpload,
    setTemplate,
    onUploadTemplate,
    snackBarProps,
    duplicateItems,
    showDupErrors,
    errorDia,
    setErrorDia,
    resetFileUpload,
    showFooter = true,
    date,
    setDate,
    description,
    setDescription,
    IsExcelMatch,
    setIsExcelMatch,
    StatementBalance,
    setStatementBalance,
    onCreateWihoutExcel,
    onUpdateWihoutExcel,
    editValue,
    reUpload = false,
  } = props

  const {
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    onClickButton,
    label,
  } = snackBarProps

  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    errors,
  } = useForm<BankReconciliationProps>()

  return (
    <div>
      {showDupErrors && errorDialog(errorDia, setErrorDia, duplicateItems)}
      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          resetFileUpload()
          setUpload('')
          setTemplate([])
          setOpenUpload(false)
        }}
        sections={{
          header: {
            title: 'Bank Statement',
            rightText: !!editValue ? 'Edit' : 'Add',
          },
          body: () => (
            <>
              {!reUpload && (
                <div className="content-container">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      as={KeyboardDatePicker}
                      name="DocumentDate"
                      label="Date"
                      control={control}
                      format="dd/MM/yyyy"
                      value={watch('DocumentDate')}
                      margin="normal"
                      allowKeyboardControl
                      fullWidth
                      required
                      ref={register}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(date: Date | null) => {}}
                      onAccept={(date: Date | null) => {
                        setDate(date)
                      }}
                      defaultValue={date ?? new Date()}
                      showTodayButton
                    />
                  </MuiPickersUtilsProvider>

                  <Controller
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        allowNegative={true}
                        customInput={TextField}
                        margin="normal"
                        label="Bank Statement Balance"
                        required
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        //value={StatementBalance}
                        defaultValue={StatementBalance}
                        onChange={e => {
                          setStatementBalance(
                            Number(e.target.value.replaceAll(',', ''))
                          )
                        }}
                      />
                    )}
                    control={control}
                    name="BankStatementBalance"
                    ref={register}
                    // helperText={errors?.Quantity?.message}
                    // error={errors?.Quantity ? true : false}
                  />

                  <TextField
                    name="Description"
                    label="Description"
                    autoComplete="off"
                    multiline={true}
                    // required
                    fullWidth
                    required
                    defaultValue={description}
                    onChange={e => {
                      setDescription(e?.target?.value)
                    }}
                  />

                  {!(
                    editValue?.BankReconciliationMatch?.Ledger?.length > 0
                  ) && (
                    <div
                      className="content-wrap full"
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        marginTop: '10px',
                      }}
                    >
                      <span className="flex-space xstitle">
                        Match with Excel Template :{' '}
                      </span>

                      <Switch
                        checked={IsExcelMatch}
                        onChange={event => {
                          setIsExcelMatch(event.target.checked)
                        }}
                        name="IsExcelMatch"
                        color="primary"
                      />
                    </div>
                  )}
                </div>
              )}

              {!!IsExcelMatch &&
                !(editValue?.BankReconciliationMatch?.Ledger?.length > 0) && (
                  <InputUpload
                    accept={'.xlsx, .xls, .cxv'}
                    value={upload}
                    label="Bank Statement Upload"
                    required={!editValue ? true : false}
                    multiline={true}
                    handleUpload={e => onSelectFile(e)}
                    className="p-b-20"
                  />
                )}
            </>
          ),
          leftFooterIcon: (
            <span style={{ width: '100%' }}>
              {/* <IconButton
                edge="start"
                style={{
                  color: 'black',
                  marginTop: '-20px',
                  marginBottom: '-15px',
                }}
                onClick={() => {
                  onDownloadTemplate({
                    name: `Bank Reconsilation SpreadSheet`,
                    workSheetName: `Bank Reconsilation List`,
                    headerSetup: BankReconExcelForm,
                    isHeaderLocked: true,
                    defaultHeaderFillStyle: {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'D3D3D3' },
                    },
                    defaultHeaderBorderStyle: {
                      top: { style: 'thin' },
                      left: { style: 'thin' },
                      bottom: { style: 'thin' },
                      right: { style: 'thin' },
                    },
                    listType: {
                      hasCountry: true,
                      hasNationality: true,
                    },
                  })
                }}
              >
                <IconText
                  icon={
                    <img
                      src={ExcelIcon}
                      style={{ width: '19px', paddingRight: '5px' }}
                    />
                  }
                  font="mdDesc"
                />
              </IconButton> */}

              <Button
                variant="contained"
                size="small"
                className="btn-dialog btn-primary"
                onClick={() => {
                  onDownloadTemplate({
                    name: `Bank Reconciliation SpreadSheet`,
                    workSheetName: `Bank Reconciliation List`,
                    headerSetup: BankReconExcelForm,
                    isHeaderLocked: true,
                    defaultHeaderFillStyle: {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'D3D3D3' },
                    },
                    defaultHeaderBorderStyle: {
                      top: { style: 'thin' },
                      left: { style: 'thin' },
                      bottom: { style: 'thin' },
                      right: { style: 'thin' },
                    },
                    listType: {
                      hasCountry: true,
                      hasNationality: true,
                    },
                  })
                }}
                style={{
                  float: 'left',
                }}
                color="primary"
              >
                <IconText
                  icon={
                    <img
                      src={ExcelIcon}
                      style={{ width: '19px', paddingRight: '5px' }}
                    />
                  }
                  font="mdDesc"
                  children={'Download'}
                />
              </Button>
            </span>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => [
                    setOpenUpload(false),
                    setUpload(''),
                    setTemplate([]),
                    resetFileUpload(),
                  ],
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    !!upload
                      ? onUploadTemplate()
                      : !editValue
                      ? onCreateWihoutExcel()
                      : onUpdateWihoutExcel()
                  },
                  variant: 'contained',
                  color: 'primary',
                  disabled:
                    !!IsExcelMatch && !editValue
                      ? !!!upload ||
                        description === '' ||
                        StatementBalance === 0
                      : description === '' || StatementBalance === 0,
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        setOpen={setOpenSnackBar}
        label={label}
        onClickButton={onClickButton}
      />
    </div>
  )
}

const errorDialog = (
  openErrDialog: boolean,
  setOpenErrDia: React.Dispatch<boolean>,
  duplicateItems: string[]
) => (
  <CommonDialog
    fullWidth={true}
    open={openErrDialog}
    onClose={() => {
      setOpenErrDia(false)
    }}
    sections={{
      header: { title: 'Item(s) exists' },
      body: () => (
        <>
          <div>
            <span className="mdDesc text-noflow">
              Unable to upload the following item(s).
              <List>
                {duplicateItems?.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      style={{ height: '10px' }}
                      primary={
                        <span className="mdDesc flex-space">{`- ${v}`}</span>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </span>
            <span className="desc text-noflow">
              Please remove duplicated items and try again.
            </span>
          </div>
        </>
      ),
      footer: {
        actions: [
          {
            displayText: 'Close',
            props: {
              onClick: () => {
                setOpenErrDia(false)
              },
              variant: 'contained',
              color: 'primary',
            },
          },
        ],
      },
    }}
  />
)
