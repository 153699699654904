import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import {
  useGetCompanySummaryLazyQuery,
  useGetStandardLetterLazyQuery,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const StandardLetterListing = (props: any) => {
  const { mode } = props
  const { pageMode, CompanyID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const [
    loadCompany,
    {
      loading: GetCompanySummaryLoading,
      data: { getCompany: curCompany } = { getCompany: [] },
    },
  ] = useGetCompanySummaryLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const [
    loadStandardLetter,
    {
      loading: GetStandardLetterLoading,
      data: { getStandardLetter } = { getStandardLetter: [] },
    },
  ] = useGetStandardLetterLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  let headerTitle

  switch (pageMode) {
    case 'general-ledger':
      headerTitle = 'General Ledger'
      break
    case 'cash-book':
      headerTitle = 'Cash Book'
      break
    case 'account-receivable':
      headerTitle = 'Accounts Receivable'
      break
    case 'account-payable':
      headerTitle = 'Accounts Payable'
      break
    case 'inventory-control':
      headerTitle = 'Inventory Control'
      break
    case 'general-purchase':
      headerTitle = 'General Purchase'
      break
  }

  useEffect(() => {
    if (pageMode === 'general-ledger') {
      loadStandardLetter({ variables: { LetterCategory: 'GENERAL_LEDGER' } })
    } else if (pageMode === 'cash-book') {
      loadStandardLetter({ variables: { LetterCategory: 'CASH_BOOK' } })
    } else if (pageMode === 'account-receivable') {
      loadStandardLetter({ variables: { LetterCategory: 'ACC_RECEIVABLE' } })
    } else if (pageMode === 'account-payable') {
      loadStandardLetter({ variables: { LetterCategory: 'ACC_PAYABLE' } })
    } else if (pageMode === 'inventory-control') {
      loadStandardLetter({ variables: { LetterCategory: 'INVENTORY_CONTROL' } })
    } else if (pageMode === 'general-purchase') {
      loadStandardLetter({ variables: { LetterCategory: 'GENERAL_PURCHASE' } })
    }

    if (mode === 'company-setting') {
      loadCompany({ variables: { CompanyID } })
    }
  }, [pageMode])

  return (
    <>
      {GetCompanySummaryLoading && <Loading />}
      {GetStandardLetterLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname:
              mode === 'general-setting'
                ? `/${pageMode}/general-setting`
                : `/${pageMode}/${CompanyID}/company-setting`,
          })
        }}
        smTitle={headerTitle}
        title={user?.companyName}
        routeSegments={
          mode === 'general-setting'
            ? [
                { name: 'System Admin' },
                { name: 'System Admin' },
                { name: 'Document Template', current: true },
              ]
            : [
                { name: 'System Admin' },
                { name: 'Company Document Template', current: true },
              ]
        }
      />
      {mode === 'company-setting' ? (
        <DynamicSubHeader title={curCompany[0]?.Name} />
      ) : null}
      <ContentWrapper
        singleDynamicInfo={mode === 'company-setting' ? true : false}
      >
        {getStandardLetter?.length === 0 ? (
          <EmptyList
            title="No Document Template"
            subtitle="Please create new Document Template"
          />
        ) : (
          <List className="core-list">
            {getStandardLetter?.map((v, i) => (
              <ListItem
                onClick={() =>
                  history.push({
                    pathname:
                      mode === 'general-setting'
                        ? `/${pageMode}/general-setting/standard-letter/${v?.StandardLetterID}/letter-template`
                        : `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${v?.StandardLetterID}/company-letter-template`,
                  })
                }
              >
                <ListItemText
                  primary={<div className="xsTitle">{v?.Name}</div>}
                  secondary={
                    <span className="desc">
                      {mode === 'company-setting'
                        ? v?.CompanyLetterTemplateSubscription?.filter(
                            x =>
                              x?.CompanyID === CompanyID &&
                              x?.RecordStatus === 'ACTIVE'
                          )?.length ?? 0
                        : v?.LetterTemplateSubscription?.length ?? 0}{' '}
                      Template(s)
                    </span>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname:
                            mode === 'general-setting'
                              ? `/${pageMode}/general-setting/standard-letter/${v?.StandardLetterID}/letter-template`
                              : `/${pageMode}/${CompanyID}/company-setting/company-standard-letter/${v?.StandardLetterID}/company-letter-template`,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
