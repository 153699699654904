import {
  useGetAccountPeriodYearPeriodLazyQuery,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreReportLazyQuery,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaCodeQuery,
} from '@account-root/account-react/src/generated/graphql'
import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface GeneralLedgerStatusParamsProps {
  CompanyID: string
  StartDate: Date
  EndDate: Date
  AccountName: string
  FPeriod: number
  FYear: number
  DocNo: string
  RefNo: string
  MasterCOAID: string
  Department: string
  Status: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const GeneralLedgerStatusParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: yup.string().required('Company is required'),
    StartDate: yup.string().required('Start Date is required'),
    EndDate: yup.string().required('End Date is required'),
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    GeneralLedgerStatusParamsProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadEntityCOA({ variables: { CompanyID: latestCompany } })

        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            // IsLastNode: true,
          },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadEntityCOA({ variables: { CompanyID: latestCompany } })

        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            // IsLastNode: true,
          },
        })
      }
    },
  })

  const {
    loading: masterCOALoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  const [
    loadEntityCOA,
    {
      loading: EntityCOALoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  const [
    loadAccountPeriod,
    {
      loading: accountPeriodLoading,
      data: { getAccountPeriod } = { getAccountPeriod: [] },
    },
  ] = useGetAccountPeriodYearPeriodLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })
  const [
    loadCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreReportLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const ParentCostCentreID = [
    ...new Set(
      getCostCentre
        ?.filter(item => item.ParentCostCentreID !== null)
        ?.map(x => x?.ParentCostCentreID)
    ),
  ]

  const divisionData = getCostCentre?.filter(x =>
    ParentCostCentreID.includes(x?.CostCentreID)
  )

  const getFinancialAccountPeriod = getAccountPeriod
    ?.filter(x => x?.FYear === watch('FYear'))
    ?.sort((a, b) => a.FPeriod - b.FPeriod)

  const [selectedMasterCOA, setSelectedMasterCOA] = useState(new Set())

  const handleCheckboxMasterCOAChange = (event: any) => {
    let accountcode = selectedMasterCOA

    if (event?.target?.checked) {
      accountcode.add(event?.target?.value)
    } else {
      accountcode.delete(event?.target?.value)
    }
    setSelectedMasterCOA(new Set(accountcode))
  }

  const [selectedDepartment, setSelectedDepartment] = useState(new Set())

  const handleCheckboxDepartmentChange = (event: any) => {
    let department = selectedDepartment

    if (event?.target?.checked) {
      department.add(event?.target?.value)
    } else {
      department.delete(event?.target?.value)
    }
    setSelectedDepartment(new Set(department))
  }

  const [selectedDivision, setSelectedDivision] = useState(new Set())

  const handleCheckboxDivisionChange = (event: any) => {
    let division = selectedDivision

    if (event?.target?.checked) {
      division.add(event?.target?.value)
    } else {
      division.delete(event?.target?.value)
    }
    setSelectedDivision(new Set(division))
  }

  const statusArray = [
    { Value: 'COMPLETED', Name: 'Approved' },
    { Value: 'ACTIVE', Name: 'Draft' },
    { Value: 'REJECTED', Name: 'Rejected' },
    { Value: 'SUBMIT', Name: 'Submitted' },
    { Value: 'CANCELLED', Name: 'Cancelled' },
  ]

  const [selectedStatus, setSelectedStatus] = useState(new Set())

  const handleCheckboxStatus = (event: any) => {
    let status = selectedStatus

    if (event?.target?.checked) {
      status.add(event?.target?.value)
    } else {
      status.delete(event?.target?.value)
    }
    setSelectedStatus(new Set(status))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/digital-reports/general-ledger/general-ledger-status-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        StartDate: data?.StartDate ? new Date(data.StartDate) : null,
        EndDate: data?.EndDate ? new Date(data?.EndDate) : null,
        AccountName: data?.AccountName ? data.AccountName : undefined,
        FYear: data.FYear ? data.FYear : undefined,
        FPeriod: data.FPeriod ? data.FPeriod : undefined,
        DocNo: data?.DocNo ? data.DocNo : undefined,
        RefNo: data?.RefNo ? data?.RefNo : undefined,
        MasterCOAID: selectedMasterCOA
          ? Array.from(selectedMasterCOA)?.toString()
          : undefined,
        Division: selectedDivision
          ? Array.from(selectedDivision)?.toString()
          : undefined,
        Department: selectedDepartment
          ? Array.from(selectedDepartment)?.toString()
          : undefined,
        Status: selectedStatus
          ? Array.from(selectedStatus)?.toString()
          : undefined,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {masterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {accountPeriodLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/general-ledger`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'GL Listing with status', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadEntityCOA({
                        variables: { CompanyID: newValue?.CompanyID },
                      })

                      loadAccountPeriod({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          orderByAsc: 'FYear',
                        },
                      })

                      loadCostCentre({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          //   IsLastNode: true,
                        },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Doc Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="left"
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Doc Date"
              control={control}
              onChange={(date: Date | null) => {
                setValue('EndDate', date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="right"
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Doc No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          {!accountPeriodLoading && (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <Autocomplete
                    options={
                      !!watch(`CompanyID`)
                        ? getAccountPeriod
                            ?.map(el => el?.FYear)
                            .filter((element, index) => {
                              return (
                                getAccountPeriod
                                  ?.map(el => el?.FYear)
                                  .indexOf(element) === index
                              )
                            })
                            .sort((a, b) => b - a)
                        : []
                    }
                    getOptionLabel={option => `${option}`}
                    noOptionsText="Please set up Account Period"
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('FYear', newValue)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props}</span>
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            error={errors?.FYear ? true : false}
                            label="Financial Year"
                            style={{ width: '100%' }}
                            margin="normal"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Financial Year"
              name="FYear"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              error={errors?.FYear ? true : false}
            />
          )}

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getFinancialAccountPeriod || []}
                  getOptionLabel={option => String(option?.FPeriod)}
                  noOptionsText="Please set up Account Period"
                  fullWidth
                  value={
                    getFinancialAccountPeriod.find(
                      option => option?.FPeriod === value
                    ) || null
                  }
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <span className="">
                        {`${props?.FPeriod} | ${formatDate(
                          props?.StartDate
                        )} - ${formatDate(props?.EndDate)}`}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          label="Financial Period"
                          style={{ width: '100%' }}
                          margin="dense"
                          type="number"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Financial Period"
            name="FPeriod"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            required
            error={errors?.FPeriod ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={masterCOAList || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.MasterCOAID)
                    })

                    setSelectedMasterCOA(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.MasterCOAID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxMasterCOAChange(event)
                        }}
                      />
                      {`${option?.Code}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Code"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="MasterCOAID"
            label="Account Code"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={getCostCentre || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.CostCentreID)
                    })

                    setSelectedDepartment(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CostCentreID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDepartmentChange(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Department"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="Department"
            label="Department"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={statusArray || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.Value)
                    })

                    setSelectedStatus(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.Value}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStatus(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Status"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="Status"
            label="Status"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Confirm',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
