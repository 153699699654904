import { StockBalanceByDocDateReport } from './GeneratedReport/StockBalanceByDocDateReport'
import { StockBalanceByTrxDateReport } from './GeneratedReport/StockBalanceByTrxDateReport'
import { StockLedgerByLocationReport } from './GeneratedReport/StockLedgerByLocationReport'
import { ICDigitalReportSubmenu } from './ICDigitalReportSubmenu'
import { StockBalanceByDocDateParamsForm } from './Parameters/StockBalanceByDocDateParamsForm'
import { StockBalanceByTrxDateParamsForm } from './Parameters/StockBalanceByTrxDateParamsForm'
import { StockLedgerByLocationParamsForm } from './Parameters/StockLedgerByLocationParamsForm'
import React from 'react'
import { StockUsageByDepartmentParamsForm } from './Parameters/StockUsageByDepartmentParamsForm'
import { StockUsageByDepartmentReport } from './GeneratedReport/StockUsageByDepartmentReport'
import { StockTransferReportParamsForm } from './Parameters/StockTransferReportParamsForm'
import { StockTransferReport } from './GeneratedReport/StockTransferReport'
import { UserActivityLogParamsForm } from 'containers/GeneralLedgerModule/GLDigitalReportModule/Parameters/UserActivityLogParamsForm'
import { UserActivityLogReport } from 'containers/GeneralLedgerModule/GLDigitalReportModule/GeneratedReport/UserActivityLogReport'
import { StockLedgerByTrxDateParamsForm } from './Parameters/StockLedgerByTrxDateParamsForm'
import { StockLedgerByTransactionDateReport } from './GeneratedReport/StockLedgerByTrxDateReport'

const icDigitalReportRoute = [
  {
    props: {
      exact: true,
      path: '/digital-reports/inventory-control',
    },
    component: <ICDigitalReportSubmenu mode="inventory-control" />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-location-report/parameters',
    },
    component: <StockLedgerByLocationParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-location-report/generated',
    },
    component: <StockLedgerByLocationReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-document-date/parameters',
    },
    component: <StockBalanceByDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-document-date/generated',
    },
    component: <StockBalanceByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-transaction-date/parameters',
    },
    component: <StockBalanceByTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-transaction-date/generated',
    },
    component: <StockBalanceByTrxDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-usage-by-department/parameters',
    },
    component: <StockUsageByDepartmentParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-usage-by-department/generated',
    },
    component: <StockUsageByDepartmentReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-transfer-report/parameters',
    },
    component: <StockTransferReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-transfer-report/generated',
    },
    component: <StockTransferReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/user-activity-log-report/parameters',
    },
    component: (
      <UserActivityLogParamsForm
        submenu="inventory-control"
        smTitle="Inventory Control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/user-activity-log-report/generated',
    },
    component: (
      <UserActivityLogReport
        submenu="inventory-control"
        smTitle="Inventory Control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-transacton-date-report/parameters',
    },
    component: <StockLedgerByTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-transaction-date-report/generated',
    },
    component: <StockLedgerByTransactionDateReport />,
  },
]

export default icDigitalReportRoute
