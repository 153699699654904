import React, { lazy } from 'react'

const GPDigitalReportSubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GPDigitalReportSubmenu'
  ).then(module => ({
    default: module.GPDigitalReportSubmenu,
  }))
)

const GPPO4WaysMatchReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GPPO4WaysMatchReport'
  ).then(module => ({
    default: module.GPPO4WaysMatchReport,
  }))
)

const GPPO4WaysMatchParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GPPO4WaysMatchParamsForm'
  ).then(module => ({
    default: module.GPPO4WaysMatchParamsForm,
  }))
)

const POGRNGRTNAnalysisParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POGRNGRTNAnalysisParamsForm'
  ).then(module => ({
    default: module.POGRNGRTNAnalysisParamsForm,
  }))
)

const POGRNGRTNAnalysisReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POGRNGRTNAnalysisReport'
  ).then(module => ({
    default: module.POGRNGRTNAnalysisReport,
  }))
)

const GRNGRTNAnalysisByItemCategoryParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNAnalysisByItemCategoryParamsForm'
  ).then(module => ({
    default: module.GRNGRTNAnalysisByItemCategoryParamsForm,
  }))
)

const GRNGRTNAnalysisByItemCategoryReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNAnalysisByItemCategoryReport'
  ).then(module => ({
    default: module.GRNGRTNAnalysisByItemCategoryReport,
  }))
)

const GRNGRTNDailyReportParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNDailyReportParamsForm'
  ).then(module => ({
    default: module.GRNGRTNDailyReportParamsForm,
  }))
)

const GRNGRTNDailyReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNDailyReport'
  ).then(module => ({
    default: module.GRNGRTNDailyReport,
  }))
)

const TotalPurchaseBySupplierParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/TotalPurchaseBySupplierParamsForm'
  ).then(module => ({
    default: module.TotalPurchaseBySupplierParamsForm,
  }))
)

const TotalPurchaseBySupplierReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/TotalPurchaseBySupplierReports'
  ).then(module => ({
    default: module.TotalPurchaseBySupplierReport,
  }))
)

const GRNGRTNDepartmentParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNDepartmentParamsForm'
  ).then(module => ({
    default: module.GRNGRTNDepartmentParamsForm,
  }))
)

const GRNGRTNDepartmentReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNDepartmentReport'
  ).then(module => ({
    default: module.GRNGRTNDepartmentReport,
  }))
)

const GRNGRTNWarehouseParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/GRNGRTNWarehouseParamsForm'
  ).then(module => ({
    default: module.GRNGRTNWarehouseParamsForm,
  }))
)

const GRNGRTNWarehouseReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/GRNGRTNWarehouseReport'
  ).then(module => ({
    default: module.GRNGRTNWarehouseReport,
  }))
)

const POItemPurchasesParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POItemPurchasesParamsForm'
  ).then(module => ({
    default: module.POItemPurchasesParamsForm,
  }))
)

const POItemPurchasesReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POItemPurchasesReport'
  ).then(module => ({
    default: module.POItemPurchasesReport,
  }))
)

const POPurchasesByItemCategoryParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POPurchasesByItemCategoryParamsForm'
  ).then(module => ({
    default: module.POPurchasesByItemCategoryParamsForm,
  }))
)

const POPurchasesByItemCategoryReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POPurchasesByItemCategoryReport'
  ).then(module => ({
    default: module.POPurchasesByItemCategoryReport,
  }))
)

const POOutstandingReportParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POOutstandingReportParamsForm'
  ).then(module => ({
    default: module.POOutstandingReportParamsForm,
  }))
)

const POOutstandingReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POOutstandingReport'
  ).then(module => ({
    default: module.POOutstandingReport,
  }))
)

const SupplierPerformanceDetailReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/SupplierPerformanceDetailReport'
  ).then(module => ({
    default: module.SupplierPerformanceDetailReport,
  }))
)

const SupplierPerformanceDetailParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/SupplierPerformanceDetailParamsForm'
  ).then(module => ({
    default: module.SupplierPerformanceDetailParamsForm,
  }))
)

const POStatusParamsForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/Parameters/POStatusParamsForm'
  ).then(module => ({
    default: module.POStatusParamsForm,
  }))
)

const POStatusReport = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GPDigitalReport/GeneratedReport/POStatusReport'
  ).then(module => ({
    default: module.POStatusReport,
  }))
)

const UserActivityLogParamsForm = lazy(() =>
  import(
    '@account-root/account-react/src/containers/GeneralLedgerModule/GLDigitalReportModule/Parameters/UserActivityLogParamsForm'
  ).then(module => ({
    default: module.UserActivityLogParamsForm,
  }))
)

const UserActivityLogReport = lazy(() =>
  import(
    '@account-root/account-react/src/containers/GeneralLedgerModule/GLDigitalReportModule/GeneratedReport/UserActivityLogReport'
  ).then(module => ({
    default: module.UserActivityLogReport,
  }))
)

const gpDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase',
    },
    component: <GPDigitalReportSubmenu mode="general-purchase" />,
  },

  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/po-4-ways-match/generated',
    },
    component: <GPPO4WaysMatchReport />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/po-4-ways-match/parameters',
    },
    component: <GPPO4WaysMatchParamsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/po-vs-grn-grtn-analysis-report/parameters',
    },
    component: <POGRNGRTNAnalysisParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/po-vs-grn-grtn-analysis-report/generated',
    },
    component: <POGRNGRTNAnalysisReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-analysis-item-category/parameters',
    },
    component: <GRNGRTNAnalysisByItemCategoryParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-analysis-item-category/generated',
    },
    component: <GRNGRTNAnalysisByItemCategoryReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-daily-report/parameters',
    },
    component: <GRNGRTNDailyReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/grn-grtn-daily-report/generated',
    },
    component: <GRNGRTNDailyReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/total-purchase-by-supplier/parameters',
    },
    component: <TotalPurchaseBySupplierParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/total-purchase-by-supplier/generated',
    },
    component: <TotalPurchaseBySupplierReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-department-report/parameters',
    },
    component: <GRNGRTNDepartmentParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-department-report/generated',
    },
    component: <GRNGRTNDepartmentReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-warehouse-report/parameters',
    },
    component: <GRNGRTNWarehouseParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/grn-grtn-warehouse-report/generated',
    },
    component: <GRNGRTNWarehouseReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/purchases-analysis-by-item/parameters',
    },
    component: <POItemPurchasesParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/purchases-analysis-by-item/generated',
    },
    component: <POItemPurchasesReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/purchases-by-item-category/parameters',
    },
    component: <POPurchasesByItemCategoryParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/purchases-by-item-category/generated',
    },
    component: <POPurchasesByItemCategoryReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/po-outstanding-report/parameters',
    },
    component: <POOutstandingReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/po-outstanding-report/generated',
    },
    component: <POOutstandingReport />,
  },

  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/supplier-performance-detail/generated',
    },
    component: <SupplierPerformanceDetailReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/supplier-performance-detail/parameters',
    },
    component: <SupplierPerformanceDetailParamsForm />,
  },

  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/po-status/parameters',
    },
    component: <POStatusParamsForm />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/general-purchase/po-status/generated',
    },
    component: <POStatusReport />,
  },

  //User Activity Log Report
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/user-activity-log-report/parameters',
    },
    component: (
      <UserActivityLogParamsForm
        submenu="general-purchase"
        smTitle="General Purchase"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/general-purchase/user-activity-log-report/generated',
    },
    component: (
      <UserActivityLogReport
        submenu="general-purchase"
        smTitle="General Purchase"
      />
    ),
  },
]

export default gpDigitalReportRoutes
